
import { Component, Prop, Vue, Mixins } from 'vue-property-decorator';
import { clipCreationToolStore } from "../../store";
import { mdiMovie, mdiVideoPlus, mdiCached } from '@mdi/js';
import { VuetifyMixin } from '../../mixins';
import { getMediumDateString, formatDurationShort } from '../../helpers/date';
import { SourceVideoUsageType } from '../../../types/enums/sourcevideos';

@Component({
	components: {
	}
})
export default class SourceVideoList extends Mixins(VuetifyMixin){
	mdiMovie = mdiMovie;
	mdiVideoPlus = mdiVideoPlus;
	mdiCached = mdiCached;

	@Prop() public typeOfParentObject: string;
	@Prop() public theParentObjectId: string;

	get sourceVideosLoaded() {
		return clipCreationToolStore.sourceVideosLoaded;
	}

	datestring(theDate: Date): string {
		return getMediumDateString(theDate);
	}

	formatDurationShort(theDuration: number): string {
		return formatDurationShort(theDuration, true, 's');
	}

	async refreshList() {
		switch (this.typeOfParentObject) {
		// backend not implemented yet.
		// case SourceVideoUsageType.Athlete:
		//   await clipCreationToolStore.loadAllSourceVideosFromDB(this.theParentObjectId);
		//   break;
		case "Evaluation":
			await clipCreationToolStore.loadSourceVideosForEvaluationAssignment(this.theParentObjectId);
			break;
		case "TeamEvent":
			await clipCreationToolStore.loadSourceVideosForTeamEvent(this.theParentObjectId);
			break;
		default:
			break;
		}
	}

	async mounted() {

		console.log("In call to mounted() for SourceVideo list");
		console.log("Parent Object type is: ", this.typeOfParentObject);
		console.log("Parent Object ID is: ", this.theParentObjectId);
		this.refreshList();
		
	}

}
