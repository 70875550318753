
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiSoccer } from '@mdi/js';
import Page from '../../views/Page.vue';
import AssignmentChip from '../../components/ui/AssignmentChip.vue';
import AssignmentCard from '../../components/courses/AssignmentCard.vue';
import CourseHeader from '../../components/courses/CourseHeader.vue';
import { MyStudentCoursesMixin, VuetifyMixin } from '../../mixins';
import { AssignmentModel } from '../../models/course';
import { formatDateSlashes } from '../../helpers/date';

@Component({
	components: {
		AssignmentChip,
		AssignmentCard,
		CourseHeader,
		Page,
	}
})
export default class StudentCourse extends Mixins(MyStudentCoursesMixin, VuetifyMixin){
	mdiSoccer = mdiSoccer;
	get MyStudentCurrentCourseName() : string{
		if(!this.MyStudentCurrentCourse) return "";
		return this.MyStudentCurrentCourse.name;
	}
	get StartDateFormatted() : string{
		if(!this.MyStudentCurrentCourse || !this.MyStudentCurrentCourse.startDate) return '--';
		return formatDateSlashes(this.MyStudentCurrentCourse.startDate);
	}
	get EndDateFormatted() : string{
		if(!this.MyStudentCurrentCourse || !this.MyStudentCurrentCourse.endDate) return '--';
		return formatDateSlashes(this.MyStudentCurrentCourse.endDate);
	}
	@Prop({ required: true }) courseId: string;

	get CurrentAssignments(): AssignmentModel[]{
		// console.log("Current Assignments: ", this.MyStudentCurrentAssignments);
		return this.MyStudentCurrentAssignments.filter(a => a.status !== "Completed");
	}
	get CompletedAssignments(): AssignmentModel[]{
		return this.MyStudentCurrentAssignments.filter(a => a.status === "Completed");
	}

	get NoAssignments(): boolean{
		// console.log("No assignments :", this.MyStudentCurrentAssignments);
		return this.MyStudentCurrentAssignments.length === 0;
	}
}
