import { render, staticRenderFns } from "./AssignmentCard.vue?vue&type=template&id=3e88cc54&scoped=true&"
import script from "./AssignmentCard.vue?vue&type=script&lang=ts&"
export * from "./AssignmentCard.vue?vue&type=script&lang=ts&"
import style0 from "./AssignmentCard.vue?vue&type=style&index=0&id=3e88cc54&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e88cc54",
  null
  
)

export default component.exports