
import { Component, Mixins } from 'vue-property-decorator';
import { mdiChevronLeft } from '@mdi/js';
import AssignmentChip from '@/components/ui/AssignmentChip.vue';
import Discussion from '@/components/courses/Discussion.vue';
import RubricForm from '@/components/courses/RubricForm.vue';
import AttachmentsArea from '@/components/forms/AttachmentsArea.vue';
import ProfilePictureProvider from '@/components/hoc/ProfilePictureProvider.vue';
import SingleCommentTextArea from '@/components/courses/SingleCommentTextArea.vue';
import VideoEvaluationSingleClipPlayer from '@/components/videoclips/VideoEvaluationSingleClipPlayer.vue';
import { MyStudentCoursesMixin, RubricMixin, VuetifyMixin } from '@/mixins';
import { AssignmentModel, RubricModel } from '@/models/course';
import { StaffMember } from '@/models/team/StaffMember';
import { MarkupTagClipMuxModel } from '@/models/markup-tag-clip-mux/MarkupTagClipMuxModel';
import { DomainObjectChangeOptions } from 'types/interfaces';
import { CommentCreatedEvents } from 'types/interfaces/CommentCreateMetadata';

@Component({
	components: {
		AssignmentChip,
		AttachmentsArea,
		Discussion,
		SingleCommentTextArea,
		RubricForm,
		VideoEvaluationSingleClipPlayer,
		ProfilePictureProvider
	}
})
export default class ViewEvaluation extends Mixins(MyStudentCoursesMixin, RubricMixin, VuetifyMixin){
	mdiChevronLeft = mdiChevronLeft;

	get FileUploadParentId(): string{
		return this.MyStudentCurrentAssignmentId;
	}
	get FileUploadApiPrefix(): string{
		return `course/${this.MyStudentCurrentCourseId}/student/${this.MyStudentId}/assignment/${this.MyStudentCurrentAssignmentId}/files`;
	}
	
	showFullScreenClipPlayer: boolean = false;
	selectedClipToPlay: MarkupTagClipMuxModel;

	get EvaluationReady(): boolean{
		return this.CurrentAssignment !== null;
	}

	get CommentSubmitParams(): CommentCreatedEvents & DomainObjectChangeOptions{
		return {
			notify: true,
			type: "Course:AssignmentEvaluation",
			payload:{
				courseId: this.MyStudentCurrentCourseId,
				assignmentId: this.MyStudentCurrentAssignmentId,
				studentId: this.MyStudentId,
				coachId: this.MyCoachId,
			},
		}
	}

	get AssignmentRubric(): RubricModel | null{
		// if(!this.RubricTemplates[0]) return null;
		// return this.RubricTemplates[0];
		if (!this.CurrentAssignment) return null;
		return this.CurrentAssignment.rubric;
	}
	get CurrentAssignment(): AssignmentModel | null{
		if(!this.MyStudentCoursesReady) return null;
		return this.MyStudent ? this.MyStudent.assignments.find(a => a.id === this.$route.params.assignmentId) : null;
	}

	get HasInstructor(): boolean{
		return this.CurrentInstructor !== undefined;
	}
	get CurrentInstructor(): StaffMember | undefined{
		return this.MyStudentCurrentCourse.staffMembers[0];
	}

	goBack(): void{
		window.history.back();
	}
	
	openClipPlayerToSpecificClip(clipToPlay: MarkupTagClipMuxModel): void {
		if (!this.showFullScreenClipPlayer) {
			this.selectedClipToPlay = clipToPlay;
			this.showFullScreenClipPlayer = true;
		}

	}
}
